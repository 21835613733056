export const FullstackCurriculum = [
    {
        week: 1,
        description: 'Introduction to Web Development, HTML & CSS Basics',
        days: [
            'Course introduction and overview, Setting up the development environment (VS Code, Git, GitHub), Introduction to web development, Basic HTML structure and tags (headings, paragraphs, lists, links, images)',
            'More HTML tags (tables, forms, semantic elements), Introduction to CSS, CSS syntax and selectors, Basic styling (colors, fonts, text alignment)',
            'CSS box model (margin, border, padding, content), CSS positioning (static, relative, absolute, fixed), Styling HTML forms, Introduction to Flexbox',
            'Advanced CSS (pseudo-classes, pseudo-elements), CSS Flexbox (flex containers, flex items, alignment, and distribution), CSS Grid basics, Responsive design principles',
            'Media queries for responsive design, CSS variables, CSS transitions and animations, Mini-project: Build a responsive web page',
        ],
    },
    {
        week: 2,
        description: 'Advanced CSS & Introduction to Bootstrap',
        days: [
            'Review of week 1, Introduction to Bootstrap, Setting up Bootstrap in projects, Bootstrap grid system',
            'Bootstrap typography and tables, Bootstrap forms and form validation, Bootstrap components (buttons, images, navbars)',
            'Bootstrap components continued (cards, carousels, modals), Customizing Bootstrap with CSS, Bootstrap utilities and helpers',
            'Advanced Bootstrap (dropdowns, collapse, tooltips, popovers), Bootstrap project setup, Building a landing page with Bootstrap',
            'Building and styling forms with Bootstrap, Bootstrap best practices, Mini-project: Create a multi-page responsive website using Bootstrap',
        ],
    },
    {
        week: 3,
        description: 'JavaScript Basics',
        days: [
            'Introduction to JavaScript, JavaScript syntax and basic operators, Variables and data types',
            'Control structures (if-else, switch), Loops (for, while, do-while), Functions and scope',
            'Arrays and array methods, Objects and object methods, DOM manipulation basics',
            'Events and event handling, Event listeners and event object, Form validation using JavaScript',
            'JavaScript debugging techniques, Introduction to JavaScript ES6 features (let, const, arrow functions), Template literals and destructuring',
        ],
    },
    {
        week: 4,
        description: 'Advanced JavaScript',
        days: [
            'Advanced functions (callbacks, closures), Asynchronous JavaScript (promises, async/await), Error handling',
            'Working with APIs (fetch, XMLHttpRequest), JSON and data manipulation, Introduction to local storage and session storage',
            'JavaScript modules and imports/exports, Working with dates and times in JavaScript, Regular expressions',
            'JavaScript best practices and coding standards, Introduction to build tools (Webpack, Babel), Setting up a project with build tools',
            'Mini-project: Build a JavaScript application, Integrating all learned concepts, Review and Q&A',
        ],
    },
    {
        week: 5,
        description: 'Introduction to React',
        days: [
            'Introduction to React, Setting up a React environment (Create React App), React components and JSX',
            'Functional components and props, State and lifecycle methods, Handling events in React',
            'Conditional rendering, Lists and keys in React, Controlled and uncontrolled components',
            'Form handling in React, Lifting state up, React best practices',
            'Mini-project: Build a simple React application, Review and Q&A',
        ],
    },
    {
        week: 6,
        description: 'Advanced React Concepts',
        days: [
            'Introduction to React Hooks (useState, useEffect), Working with useState and useEffect, Custom hooks',
            'Context API for state management, Using the Context API in applications, React Router for navigation',
            'Routing in React applications, Nested routes and dynamic routing, Programmatic navigation',
            'Advanced hooks (useMemo, useCallback, useReducer), Performance optimization in React, Code splitting and lazy loading',
            'Mini-project: Build a complex React application, Review and Q&A',
        ],
    },
    {
        week: 7,
        description: 'State Management with Redux',
        days: [
            'Introduction to Redux, Setting up Redux in a React application, Actions and reducers',
            'Working with the Redux store, Dispatching actions, Connecting Redux to React components',
            'Middleware in Redux (Redux Thunk, Redux Saga), Asynchronous actions in Redux, Redux DevTools',
            'Advanced Redux concepts (normalization, reselect), Structuring Redux applications, Redux best practices',
            'Mini-project: Build a Redux-powered React application, Review and Q&A',
        ],
    },
    {
        week: 8,
        description: 'Node.js and Express.js',
        days: [
            'Introduction to Node.js, Setting up a Node.js project, Understanding the event loop and asynchronous programming',
            'Working with Express.js, Building RESTful APIs, Middleware and routing',
            'Handling data with MongoDB, Mongoose for schema and model management',
            'Building a full-stack application: Connecting React with Node.js and Express.js',
            'Mini-project: Build a RESTful API with Node.js and Express.js, Review and Q&A',
        ],
    },
    {
        week: 9,
        description: 'MongoDB and Database Design',
        days: [
            'Introduction to MongoDB, Setting up a MongoDB database, Understanding collections and documents',
            'CRUD operations in MongoDB, Using Mongoose for data modeling, Advanced queries and indexing',
            'Database design principles, Designing a schema for a full-stack application, Implementing relationships and embedding documents',
            'Building a full-stack application: Connecting React with MongoDB',
            'Mini-project: Build a full-stack application with MongoDB, Review and Q&A',
        ],
    },
    {
        week: 10,
        description: 'Authentication and Authorization',
        days: [
            'Introduction to authentication and authorization, Understanding JWT (JSON Web Tokens)',
            'Implementing user authentication in a Node.js and Express.js application, Secure password storage and handling',
            'Implementing user authorization, Role-based access control',
            'Integrating authentication with React, Managing user sessions and state',
            'Mini-project: Build a full-stack application with authentication and authorization, Review and Q&A',
        ],
    },
    {
        week: 11,
        description: 'Advanced MERN Stack Topics',
        days: [
            'Optimizing performance in React applications, Code splitting and lazy loading',
            'Scaling Node.js applications, Implementing caching and rate limiting',
            'Working with GraphQL, Setting up a GraphQL server with Node.js and Express.js',
            'Building a full-stack application with GraphQL and React',
            'Mini-project: Build a full-stack application with advanced MERN stack topics, Review and Q&A',
        ],
    },
    {
        week: 12,
        description: 'Testing and Deployment',
        days: [
            'Introduction to testing in JavaScript, Unit testing with Jest, Testing React components with React Testing Library',
            'Integration testing with Supertest and Jest, Testing Node.js and Express.js applications',
            'End-to-end testing with Cypress, Writing and running Cypress tests, Debugging tests',
            'CI/CD pipelines for MERN stack applications, Introduction to popular CI/CD tools (GitHub Actions, Travis CI, CircleCI), Setting up a CI/CD pipeline',
            'Deployment options for MERN stack applications, Deploying applications using cloud platforms like AWS, Heroku, and Vercel, Final project planning',
        ],
    },
    {
        week: 13,
        description: 'Final Project Development',
        days: [
            'Project setup and planning, Defining project scope and requirements, Initial project structure and setup',
            'Building core features of the project, Implementing React components, State management and API integration',
            'Styling the application with CSS and Bootstrap, Adding interactivity with JavaScript, Integrating advanced React features',
            'Testing the application, Debugging and optimization, Preparing for deployment',
            'Final touches and polish, Project presentation preparation, Review and Q&A',
        ],
    },
    {
        week: 14,
        description: 'Final Project Presentations and Wrap-Up',
        days: [
            'Final project presentations (part 1), Peer reviews and feedback, Instructor feedback',
            'Final project presentations (part 2), Peer reviews and feedback, Instructor feedback',
            'Course review and recap, Career advice and next steps, Building a portfolio and resume',
            'Mock interviews and interview preparation, Networking and job search strategies, Final Q&A',
            'Course wrap-up, Certificates and celebrations, Final thoughts and feedback',
        ],
    },
];
